.icon-container{
    display: inline;
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 0.545454rem;
    padding:1%;
    margin:5%;
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_pri_0);
    color: var(--clr-primary-2);
    box-shadow: 
    10px 10px 10px -1px rgba(60,60,60, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.70);

    /*
    box-shadow: 
    -2px 2px 1px 0 rgba(120,120,120, 0.35),
    -8px -8px 11px 0 rgba(255, 255, 255, 0.3);
    */
    transition: 0.33s;
}

@keyframes slidein {
    from{left:65px}
    to {left: 0px;}

    
}
@keyframes slideout {
    from {left: 0px;}
    to{left:65px} 
}
.icon-container-title{
    z-index: 3;
    height:50px;
    border-radius: 6px;
    border-color: transparent;
    position: absolute;
    background-color: var(--clr_pri_2);
    color:var(--clr_pri_1);
    bottom: -10px;
    width: 100px;
    text-align: center;
    left:65px;
    font-size: 0.75rem;
    font-weight: 800;
    opacity: 0;
    padding-top: 7.5%;
    animation-name: slidein;
    animation-duration: 350ms;
    transition: all 350ms ease-in-out;


}
.icon-container-title-pointer{
    border:solid 10px transparent;
    border-right-color:var(--clr_pri_2);
    position:absolute ;
    margin:-25px 0 0 -19px;}

.icon-container:hover{
    color: var(--clr_1_inv);
    /*
    box-shadow: 
    -2px 2px 1px 0 rgba(255,255,255, 1),
    -8px -8px 11px 0 rgba(255, 255, 255, 0.3);
    */
    box-shadow: 
     1px 1px 1px -1px rgba(60,60,60, 0.16),
     -1px -1px 1px -1px rgba(255, 255, 255, 0.70);
    transition: 350ms;
}
.icon-container:hover .icon-container-title{
    background-color: var(--clr_pri_2);
    color:var(--clr_pri_1);
    animation-name: slideout;
    animation-duration: 350ms;
    opacity: 1;
    transition: all 350ms ease-in-out;
}



